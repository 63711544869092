import { useContext, useEffect } from 'react';
import { useNotes } from '../../redux/actions/notesAction';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './notesPage.scss';
import Spinner from '../../components/spinner/spinner';
import useHeader from '../../redux/actions/headerAction';
import { AppStateContext } from '../../monorepoClient/AppState/appState.context';
import { useUser } from '../../redux/actions/userAction';

const Notes = () => {
  const { userData } = useUser()?.user;
  const location = useLocation();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const dayNumber = queryParams.get('dayNumber');
  const weekNumber = queryParams.get('weekNumber');
  const weekTitle = queryParams.get("weekTitle");
  const { noteData, getAllNotes } = useNotes();
  const { isNotesLoading } = noteData || {};
  const { updateHeader } = useHeader();
  const createNoteUrl = (code: string) => {
    if(userData?.batchCode !== "WMB3-16092024"){
      return `https://slides.com/yashpriyam/${code}/embed?style=dark&share=hidden`;
    }
    return code

  };

  useEffect(() => {
    getAllNotes({
      dayNumber: Number(dayNumber),
      weekNumber: Number(weekNumber),
    });
  }, []);

  useEffect(() => {
    updateHeader({ title: "Notes Page", options: [] })
  }, []);
 
  return (
    <>
      <div className="notes-page-container">
        <div className="notes-time">
          <span>
            {weekTitle}
          </span>
          <span className="notes-day">
            {t('title', { title: t('day') })}
            {dayNumber}
          </span>
        </div>
        {isNotesLoading ? (
          <Spinner />
        ) : (
          noteData?.noteList?.map((note) => {
            return (
              <div className="iframe-container">
                <iframe
                  title={note?.link}
                  loading="lazy"
                  src={createNoteUrl(note?.link)}
                ></iframe>
                <p>{note?.title}</p>
                <p>
                  {t('title', { title: t('estimated_reading_time') })}
                  {note?.estimatedReadingTime}
                </p>
              </div>
            );
          })
        )}
        {noteData.noteList.length ? (
          ''
        ) : (
          <p className="notes-error">{t('notes_error')}</p>
        )}
      </div>
    </>
  );
};

export default Notes;
