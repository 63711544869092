import React from 'react';
import './SidebarUserProfile.scss';
import LinkedInLogo from "../../icons/linkedinWhiteIcon.svg";
import TwitterLogo from "../../icons/twitterxWhiteIcon.svg";
import GitHubIcon from "../../icons/githubWhiteIcon.svg";

export const SidebarUserProfile: React.FC<SidebarOptionInterface> = ({
    image,
    text,
    isProfile,
    showText,
    url,
    moreInfo,
    onClick = () => { }
}: SidebarOptionInterface) => {
    const { batchCode } = moreInfo || {};

    return (
        <>
            {showText ? (
                <div className="sidebar-user-profile" onClick={onClick}>
                    <div className="sidebar-user-profile-top">
                        <div className="sidebar-user-profile-left">
                            <div className="sidebar-user-social-media">
                                <img className="sidebar-user-social-media-img" src={LinkedInLogo} alt="" />
                            </div>
                            <div className="sidebar-user-social-media">
                                <img className="sidebar-user-social-media-img" src={TwitterLogo} alt="" />
                            </div>
                            <div className="sidebar-user-social-media">
                                <img className="sidebar-user-social-media-img" src={GitHubIcon} alt="" />
                            </div>
                        </div>
                        <div className="sidebar-user-profile-center">
                            <div className="sidebar-user-profile-center-dp">
                                {React.isValidElement(image) ? (
                                    <>{image}</>
                                ) : typeof image === 'string' ? (
                                    <img className="sidebar-user-profile-center-dp-img" src={image} alt="" />
                                ) : null}
                            </div>
                            <div className="sidebar-user-profile-center-text">
                                <h1 className="sidebar-user-profile-center-text-h1">{text}</h1>
                            </div>
                        </div>
                        <div className="sidebar-user-profile-right"></div>
                    </div>

                    <div className="sidebar-user-profile-bottom">
                        <p className="sidebar-user-profile-bottom-p">{batchCode}</p>
                    </div>
                </div>
            ) : (
                <div className="sidebar-user-profile-dp">
                    {React.isValidElement(image) ? (
                        <>{image}</>
                    ) : typeof image === 'string' ? (
                        <img className="sidebar-user-profile-dp-img" src={image} alt="" />
                    ) : null}
                </div>
            )}
        </>
    );
};
