import React, { useEffect, useState } from "react";
import "./AllUserPaymentsList.scss";
import UserPaymentCard from "../../../components/userPaymentsCard/userPaymentCard";
import { useAllUsersPayments } from "../../../redux/actions/getAllUsersPayments";
import { useTranslation } from "react-i18next";
import { status } from "../../../utils/constants";
import { useBatch } from "../../../redux/actions/batchAction";

const AllUserPaymentsList: React.FC = () => {
  const { t } = useTranslation();
  const { allUsersPayments, getAllUsersPayments } = useAllUsersPayments();
  const [isStatusUpdated,SetIsStatusUpdated]=useState(false);
  const { currentBatch } = useBatch();
  
  useEffect(() => {
    getAllUsersPayments({ paymentStatus: status.PENDING, batchId:currentBatch._id });    
  }, [isStatusUpdated, currentBatch.batchCode]);

  return (
    <div className="user-payments-list">
      <h1 className="list-title">{t("User Payments List")}</h1>
      <ul className="payment-list">
        {allUsersPayments?.allUsersPayments?.map((payment) => (
          <UserPaymentCard
            key={payment._id}
            payment={payment}
            setIsStatusChanges={SetIsStatusUpdated}
          />
        ))}
      </ul>
    </div>
  );
};

export default AllUserPaymentsList;
