import { useEffect, useState } from 'react';
import './questionPage.scss';
import QuestionAccordion from '../../components/questionAccordion/questionAccordion';
import { useQuestions } from '../../redux/actions/questionAction';
import { useQuestionAttempt } from '../../redux/actions/questionAttemptAction';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUserCode } from '../../redux/actions/userCodeActions';
import Spinner from '../../components/spinner/spinner';
import { Text } from '../../components/text/text';
import { QuestionFilter } from '../../components/questionFilter/questionFilter';
import { DsaQuestionComponent } from '../../components/dsaQuestionComponent/dsaQuestionComponent';
import { Pagination } from '../../components/pagination/pagination';
import { Select } from '../../components/select/select';
import NoDataFoundComponent from '../../components/noDataFound/noDataFound';
import useHeader from '../../redux/actions/headerAction';

const QuestionPage = () => {
  const { questions, getAllQuestions,getAllDsaQuestions } = useQuestions();
  const { createQuestionAttemptByUser } = useQuestionAttempt();
  const { getUserCode } = useUserCode();
  const [dsaQuestionList, setDsaQuestionList] = useState<GetDsaResponseType[]>([]);
  const { questions: questionList, isQuestionLoading,totalQuestions: totalNonDsaQuestions } = questions;
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dayNumber = queryParams.get('dayNumber');
  const weekNumber = queryParams.get('weekNumber');
  const { state } = location;
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(20);
  const totalQuestions = (totalNonDsaQuestions ?? 0) + (dsaQuestionList?.length ?? 0);
  const totalPages = totalQuestions ? Math.ceil(totalQuestions / limitPage) - 1 : 0;
  const paginationArrLength = 8;

  const [totalPagination, setTotalPagination] = useState(totalPages);
  const [paginationIndices, setPaginationIndices] = useState<number[]>([]);
  const { updateHeader } = useHeader();

  const skip = limitPage*(currentPage-1);
  const limitData = Array.from({ length:5 }, (x, i) => ({text:`${(i+1)*10} / Page`, value: `${(i+1)*10}`}));
  let description, title;
  description = state?.description;
  title = state?.title
    ? state.title
    : `${t('title', { title: t('week') })} ${Number(weekNumber) - 1}`;
  const onSubmit = async (
    question: QuestionDataType,
    selectedValues: QuestionSelectedValueType[]
  ) => {
    const filteredData = selectedValues.map((selectedValue) => ({
      imageUrl: selectedValue.imageUrl,
      text: selectedValue.text,
    }));
    try {
      await createQuestionAttemptByUser(filteredData, question._id);
    } catch (err) {
      console.error(err);
    }
  };
  const [tagsFilterIds, setTagsFilterIds] = useState<string[]>([]);
  const [isLoading,setIsLoading]=useState<boolean>(false)
  const getQuestionRequest = async () => {
    setIsLoading(true);
    if (weekNumber && dayNumber) {
      await getAllQuestions({ week: Number(weekNumber), day: Number(dayNumber),tags: tagsFilterIds ,skip,limit:limitPage });
      const { questionData, response } = await getAllDsaQuestions({
        week: Number(weekNumber),
        day: Number(dayNumber),
        tags: tagsFilterIds,
        skip,limit:limitPage 
      });
      setDsaQuestionList(questionData);
    } else {
      await getAllQuestions({tags: tagsFilterIds,skip,limit:limitPage  });
      const { questionData, response } = await getAllDsaQuestions({tags:tagsFilterIds,skip,limit:limitPage });
      setDsaQuestionList(questionData);
    }
    setIsLoading(false);
  };

  const handlePaginationLimit = (option: SelectOptionType) => {
    const { value } = option;
    setLimitPage(Number(value));
  }

  const paginationArr = (length: number)=> {
    let arr = [], startElem = 2;
    for (let i = 0; i < length; i++) { 
      arr[i]=startElem++;
    }
    setPaginationIndices(arr);
  }

  useEffect(() => {
    getUserCode({
      weekNumber: Number(weekNumber),
      dayNumber: Number(dayNumber),
    });
    getQuestionRequest();
  }, [weekNumber, dayNumber, tagsFilterIds,currentPage,limitPage]);

  useEffect(()=>{
    const length = totalPages > paginationArrLength ? paginationArrLength - 2 : totalPages - 2;
    !paginationIndices.length && paginationArr(length)
  },[totalPages,totalPagination]);

  useEffect(()=>{
    setTotalPagination(totalPages);
  },[totalQuestions])

  useEffect(() => {
    updateHeader({ title: "Questions Page", options: [] });
  }, [])


  if (!Boolean(questionList?.length) && !Boolean(dsaQuestionList?.length) && !isLoading) {
     return (
      <NoDataFoundComponent message="No questions found" className="noDataFound" />
     )
  } 

  return (
    <div className="question-page-container">
    <div className="question-page-sub-wrapper">
      <div className="question-time">
        {description && (
          <div className="question-page-description">
            {t('title', { title: 'Title' })}
            {description}
          </div>
        )}
        {title && dayNumber && (
          <Text textType='h3'>
            <span>{title}</span>
            <span className="question-day">
              {t('title', { title: t('day') })}
              {dayNumber}
            </span>
          </Text>
        )}
        { (Boolean(questionList?.length) || 
          Boolean(dsaQuestionList?.length)) && 
          (
          <Text textType='h3'>
            {`${t('totalQuestions')} : ${(questionList?.length ?? 0) + (dsaQuestionList?.length ?? 0)}`}
          </Text>
        )}
      </div>
      <QuestionFilter setFilter={setTagsFilterIds} disableFilter={isLoading}/>
      <div className="question-page-sub-container">
        {isLoading? <Spinner/> : questionList?.map((question, index) => {
          return (
            <QuestionAccordion
              key={index}
              questionNumber={index+1}
              questionData={question}
              onSubmit={onSubmit}
              className="accordian-customize"
              isCorrect={question.isCorrect}
              isAnswered={question.isAnswered}
              errorMsg={t('incorrect_answer')}
              successMsg={t('correct_answer')}
            />
          );
         })} 
        {!isLoading  &&
          (
            dsaQuestionList?.map((questionData, index) => {
              return (
                <DsaQuestionComponent
                  attemptResponse={questionData.attemptResponse}
                  description={questionData.description}
                  questionId={questionData.questionId}
                  meta={questionData.meta}
                  title={questionData.title}
                  questionIndex={(questionList?.length ?? 0) + index + 1}
                />
              );
            })
          )
        }
     {/* {!isLoading && 
        <div className='question-pagination-container'>
          <Select 
            data={limitData} className='question-pagination-limit-selector' 
            defaultSelected={`${limitPage} / Page`} 
            onSelect={handlePaginationLimit}
          />
          <Pagination 
            currentPage={currentPage} 
            totalPages={totalPages} 
            setCurrentPage={setCurrentPage}
            paginationLength={paginationArrLength-2}
            paginationIndices={paginationIndices}
            setPaginationIndices={setPaginationIndices}
          />
        </div>
      } */}
      </div>
      </div>
    </div>
  );
};

export default QuestionPage;