import { useEffect, useState } from "react";
import "./allUsersGoalStatus.scss";
import { useUserGoals } from "../../../redux/actions/getUserGoalsAction";
import { useTags } from "../../../redux/actions/tagsAction";
import { UserGoalStatusCard } from "../../../components/userGoalStatusCard/userGoalStatusCard";
import { Select } from "../../../components/select/select";
import Spinner from "../../../components/spinner/spinner";
import NoDataFoundComponent from "../../../components/noDataFound/noDataFound";
import { goalStatus, noFoundComponentMessages, routeUrl } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useBatch } from "../../../redux/actions/batchAction";

export const AllUsersGoalStatus: React.FC = () => {
  const { userGoalNotFound } = noFoundComponentMessages;

  const { getAllUserFilteredGoals } = useUserGoals();
  const { getAllTags, tags } = useTags();
  const { currentBatch } = useBatch();
  const [usersGoalsData, setUsersGoalsData] = useState<[]>([]);
  const [status, setStatus] = useState<string>(goalStatus.PENDING);
  const [allTagsList, setAllTagList] = useState<SelectOptionType[]>([]);
  const [isStatusUpdated, SetIsStatusUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { tagsData } = tags;
  const navigate = useNavigate();
  const setTagsData = async () => {
    setAllTagList(
      tagsData?.userGoalStatus?.map((status) => {
        return { text: status.tagName, value: status.tagKey };
      })
    );
  };
  const getAllUserGoalCompletion = async () => {
    setIsLoading(true);
    const { userGoals } = await getAllUserFilteredGoals({
      status: status,
      batchId: currentBatch._id
    });

    setIsLoading(false);
    const newGoalData = userGoals;
    setUsersGoalsData(newGoalData);
  };
  const getAllTagsApi = async () => {
    await getAllTags({ tagType: [goalStatus.TAG_TYPE] });
  };
  useEffect(() => {
    setTagsData();
  }, [tags]);
  useEffect(() => {
    getAllTagsApi();
  }, []);

  useEffect(() => {
    getAllUserGoalCompletion();
  }, [isStatusUpdated, status, currentBatch._id]);

  const handleOnSelectStatus = (option: SelectOptionType) => {
    setStatus(option.value as string);
  };
  const navigateToUserResposne = (id : string) => {
    navigate(routeUrl.USER_GOAL_RESPONSE,{ state: { id } });
  }
  return (
    <div className="all-user-goal-status-container">
      <h2 className="user-goal-status-header">User Goal Status</h2>
      <div className="goal-select-wrapper">
        <label htmlFor="batch-code" className="create-goal-label">
          Select status :
        </label>
        <Select
          className="goal-select"
          key={"is-live"}
          data={allTagsList}
          onSelect={handleOnSelectStatus}
          defaultSelected="Select goal status"
        ></Select>
      </div>
      {isLoading ? (
        <Spinner />
      ) : usersGoalsData?.length ? (
        <>
          <table className="goals-list-card-container">
            <thead className="goals-list-table-head">
              <th className="list-head title-head">Goal title</th>
              <th className="list-head name-head">Name</th>
              <th className="list-head batch-head">Batch code</th>
              <th className="list-head status-head">Current status</th>
              <th className="list-head submission-head">Submission time</th>
              <th className="list-head submission-head">Start Week</th>
              <th className="list-head submission-head">End Week</th>
            </thead>
            <tbody className="goals-list-body">
              {usersGoalsData?.map((goalData, index) => {
                return (
                  <UserGoalStatusCard
                    key={index}
                    goalData={goalData}
                    setIsStatusChanges={SetIsStatusUpdated}
                    onClick={navigateToUserResposne}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
            <NoDataFoundComponent className="no-data-found-container" height="200px" width="100%" message={userGoalNotFound} />
      )}
    </div>
  );
};

