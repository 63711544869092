import { gql } from "@apollo/client";

export const GET_ALL_BATCHES = gql`
  query GetAllBatches {
    getAllBatches {
      batchData {
        batchCode
        startDate
        _id
      }
      response {
        status
        message
      }
    }
  }
`;
