import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { actions, selectBatch } from "../slices/batch/batchSlice";
import { GET_BATCH_CODE } from "../../graphql/query/batch/getBatchCode";
import { useUser } from "./userAction";
import { GET_ALL_BATCHES } from "../../graphql/query/batch/getAllBatches";
export const useBatch = () => {
    const dispatch = useDispatch();
    const { batchData, currentBatch } = useSelector(selectBatch);
    const { batchCode } = useUser()?.user?.userData || {};

    const getBatchCode= async () => {
        try {
            const response = await apolloClient.query({
                query: GET_BATCH_CODE,
                variables: {},
            });
            dispatch(actions.setBatchCode(response.data.getBatchCode));
            return response;
        } catch (err) {
            console.error(err);
        }
    };

    const getAllBatches= async () => {
        try {
            const response = await apolloClient.query({
                query: GET_ALL_BATCHES,
                variables: {},
            });
            dispatch(actions.setBatchCode(response.data.getAllBatches));
            console.log({response});
        } catch (err) {
            console.error(err);
        }
    };

    const setCurrentBatchCode = (currBatch?: CurrentBatchType) => {
        dispatch(actions.setCurrentBatchCode(currBatch ?? {batchCode:batchCode?? ""}));
    };

    return { batchData, getBatchCode, setCurrentBatchCode, currentBatch, getAllBatches };
};