import { useDispatch, useSelector } from "react-redux";
import { apolloClient } from "../../graphql/apolloClient/apolloClient";
import { userGoalsActions, selectUserGoals } from "../slices/userGoals/userGoalsSlice";
import { GET_All_USER_GOALS } from "../../graphql/query/userGoals/userGoals";
import { GET_All_USER_COMPLETEION_GOALS } from "../../graphql/query/userGoals/getAllUserCompletionGoalFiltered";
import { GET_USER_GOAL_BY_ID } from "../../graphql/query/userGoals/getUserGoalById";

export const useUserGoals = () => {
    const dispatch = useDispatch();
    const userGoals = useSelector(selectUserGoals);
    const getUserGoals= async () => {
        try {
            dispatch(userGoalsActions.setIsGoalsDataLoading(true))
            const response = await apolloClient.query({
                query: GET_All_USER_GOALS,
                variables: {},
            });
        dispatch(userGoalsActions.setUserGoalsData(response.data.getAllUserGoals));
        return response;
    } catch (err) {
        console.error(err);
    } finally{
        dispatch(userGoalsActions.setIsGoalsDataLoading(false))
    }
    }
    const getAllUserFilteredGoals = async ({ status, batchId }: { status: string, batchId?: string }) => {
      try {
        const response = await apolloClient.query({
          query: GET_All_USER_COMPLETEION_GOALS,
          variables: {
            status: status,
            batchId
          },
        });
        return response.data.getAllUserFilteredGoals;
      } catch (err) {
        console.error(err);
      }
    };

    const getUserGoalById = async (input: GetUserGoalByIdInput) => {
      try {
        const {id, latestResponse=false} = input;
        dispatch(userGoalsActions.setIsUserGoalLoading(true))
        const response = await apolloClient.query({
          query: GET_USER_GOAL_BY_ID,
          variables: {
            input: {
              id,
              latestResponse,
            }
          },
        });
        dispatch(userGoalsActions.setUserGoalData(response.data.getUserGoalById));
        return response;
      } catch (err) {
        console.error(err);
      } finally{
        dispatch(userGoalsActions.setIsUserGoalLoading(false))
      }
    }

    return { userGoals, getUserGoals, getAllUserFilteredGoals, getUserGoalById };
}