import { gql } from "@apollo/client";

export const GET_ALL_USERS_PAYMENTS = gql`
  mutation GetAllUserPayments($paymentStatus: String, $batchId: String) {
    getAllUserPayments(paymentStatus: $paymentStatus, batchId: $batchId) {
      userPaymentData {
        _id
        installmentId
        user {
          _id
          name
          email
        }
        feePlan {
          _id
        }
        batch {
          _id
        }
        status {
          paymentStatus {
            tagName
            tagKey
            tagType
          }
          description
        }
        image {
          secureUrl
          publicId
        }
        createdAt
        updatedAt
      }
      response {
        status
        message
      }
    }
  }
`;
