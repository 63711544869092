import { gql } from "@apollo/client";


export const GET_All_USER_COMPLETEION_GOALS = gql`
  query GetAllUserFilteredGoals($status: String, $batchId: String) {
    getAllUserFilteredGoals(status: $status, batchId: $batchId) {
      response {
        status
        message
      }
      userGoals {
        _id
        currentGoalStatus {
          status {
            tagName
            tagKey
            tagType
          }
          time
        }
        userResponse {
          labels {
            label
            value
          }
          time
          response
        }
        goalId {
          _id
          title
          startWeek {
            weekNumber
          }
          endWeek {
            weekNumber
          }
        }
        userId {
          _id
          batchCode
          name
          email
        }
      }
    }
  }
`;