import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    batchData: null,
    currentBatch: {}
};

export const batchSlice = createSlice({
    name: "batch",
    initialState: initialState,
    reducers: {
        setBatchCode: (state, action) => {
            state.batchData = action.payload?.batchData;
        },
        setCurrentBatchCode: (state, action) => {
            state.currentBatch = action.payload;
        },
    },
});

export const { actions } = batchSlice;
export const selectBatch = (state: {
    batch: { batchData: BatchSchemaType[]; currentBatch: CurrentBatchType };
}) => state.batch;
export default batchSlice.reducer;